<template>
    <div class="wrapper">
        <div :class="['improve-section', customClass]" :style="{ backgroundColor: backgroundColor, color: textColor }">
            <img v-if="leftImage" :src="require('@/assets/' + leftImage)" alt="" class="improve-section-left-bottom">
            <div class="content">
                <div class="improve-section-title title big">
                    {{ title }}
                </div>
                <div v-if="description" class="improve-section-description description">
                    {{ description }}
                </div>
            </div>
            <img v-if="rightImage" :src="require('@/assets/' + rightImage)" alt="" class="improve-section-right-top">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: null
        },
        leftImage: {
            type: String,
            default: null
        },
        rightImage: {
            type: String,
            default: null
        },
        customClass: {
            type: String,
            default: ''
        },
        backgroundColor: {
            type: String,
            default: 'black'
        },
        textColor: {
            type: String,
            default: 'white'
        }
    }
}
</script>

<style scoped>
.improve-section {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    min-height: 122px;
    border-radius: 40px;
    overflow: hidden;
}

.improve-section .title.big {
    line-height: initial;
    /* 29px -> 48px,  370px -> 1440px */
    font-size: clamp(1.813rem, 1.402rem + 1.776vw, 3rem);
}

.improve-section-left-bottom,
.improve-section-right-top {
    pointer-events: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.improve-section-left-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
}

.improve-section-right-top {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
}

.content {
    text-align: center;
    width: 100%;
}

.improve-section .title {
    font-size: 48px;
    font-weight: 400;
}

.improve-section .description {
    font-family: 'Poppins', sans-serif;
    margin-top: 4px;
    line-height: 24px;
    font-size: 16px;
    opacity: 0.5;
}

.improve-section .button {
    margin: 32px auto 0;
}

@media screen and (max-width: 900px) {

    /* .improve-section-right-top {
        right: -60px;
    } */

    /* .improve-section-title {
    font-size: 26px;
} */
}

@media screen and (max-width: 768px) {

    .improve-section-left-bottom,
    .improve-section-right-top {
        display: none;
    }

    /* .improve-section-title {
        font-size: 26px;
    } */
}
</style>
