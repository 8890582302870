<template>
    <div class="more-offers-section">
        <div class="title-and-button">
            <div class="title big">{{ $t("Most popular") }}</div>
            <router-link to="/products/all" class="view-all-button">{{ $t("View all") }}</router-link>
        </div>

        <div class="top-section">
            <div class="left">
                <div class="title">{{ $t(">1k Offers for both CS2 and TF2") }}</div>
            </div>
            <div class="right">
                <img src="./../assets/img/more-offers.png" class="img" alt="More Offers" />
                <router-link to="/products/all" class="more-offers-icon">
                    <img src="./../assets/img/more-offers-icon.svg" alt="View More Offers" />
                </router-link>
            </div>
        </div>
        <div class="benefits-section">
            <div class="more-offers-card">
                <div class="ellipse"></div>
                <p>{{ $t("You may pick from a wide variety of skins") }}</p>
            </div>
            <div class="more-offers-card">
                <div class="ellipse"></div>
                <p>{{ $t("Get the latest items and skins at low prices once they arrived") }}</p>
            </div>
            <div class="more-offers-card">
                <div class="ellipse"></div>
                <p>{{ $t("You may get exclusive, rare skins and items") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MoreOffersSection',
}
</script>

<style lang="scss" scoped>
.more-offers-section {}

.title-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.view-all-button {
    color: #fff;
    font-weight: 500;
    line-height: 26px;
    font-size: 16px;
}

.top-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 24px 48px;
    border-radius: 40px;
    background: #29374A;
}

.left {}

.left .title {
    max-width: 400px;
}

.buttons {
    display: flex;
    gap: 40px;
}

.most-popular-button,
.view-all-button {}

.right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.img {
    position: absolute;
    top: 0;
    right: 0;
}

.more-offers-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 36px;
    height: 36px;
}

.benefits-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(386.67px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.more-offers-card {
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px;
    border-radius: 40px;
    background: #29374A;
}

.benefits-section .more-offers-card p:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('../assets/img/more-offers-benefits-bullets.svg');
    background-size: cover;
    margin-right: 8px;
    flex-shrink: 0;
    vertical-align: middle;
}

.more-offers-card p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
    display: flex;
}

@media (max-width: 900px) {
    .left .title {
        max-width: 100%;
    }

    .img {
        display: none;
    }
}

@media (max-width: 768px) {
    .top-section {
    }

    .benefits-section {
        grid-template-columns: 1fr;

        .more-offers-card {
            width: auto;
        }
    }
}
</style>