<template>
  <section class="section features-section">
    <div class="wrapper">
      <!-- <h2 class="section-title title">{{ $t(`All in One Place`) }}</h2> -->
      <div class="cards-container">
        <div class="card">
          <div class="card-content">
            <div class="icon-title-wrapper">
              <div class="icon"><img :src="require('@/assets/img/feature-icon-left.svg')" alt=""></div>
              <h3 class="card-title">
                {{ $t(`100% Secure`) }}
              </h3>
            </div>
            <p class="card-subtitle">
              {{ $t(`We use a variety of security measures to protect user accounts and transactions`) }}
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-content">
            <div class="icon-title-wrapper">
              <div class="icon"><img :src="require('@/assets/img/feature-icon-middle.svg')" alt=""></div>
              <h3 class="card-title">
                {{ $t(`Wide selection`) }}
              </h3>
            </div>
            <p class="card-subtitle">
              {{ $t(`We offer a wide selection of skins, including rare and exclusive items`) }}
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-content">
            <div class="icon-title-wrapper">
              <div class="icon"> <img :src="require('@/assets/img/feature-icon-right.svg')" alt=""></div>
              <h3 class="card-title">
                {{ $t(`Best Prices`) }}
              </h3>
            </div>
            <p class="card-subtitle">
              {{ $t(`We offer the best prices on TF2 skins and items. If you find a lower price on another`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.features-section {
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(386px, 1fr));
  gap: 20px;
}

.card {
  padding: 24px 24px 48px 24px;
  border-radius: 40px;
  background: #29374A;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .icon-title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
  }

  .card-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    opacity: 0.5;
  }
}

@media (max-width: 600px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
}
</style>