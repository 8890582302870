<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$emit('closeOrderModal')" />
    <div class="wrapper">
      <div class="container">
        <img src="./../assets/img/close.svg" @click="$emit('closeOrderModal')" class="close" />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods && paymentMethods.length">
            <button v-for="(item, i) in paymentMethods" class="img-btn" @click="submit(item.code)" :key="i">
              <img :src="item.image" class="img" />
              {{ item.title }}
            </button>
          </div>

          <div v-else class="desc">
            {{ $t("No payment methods available.") }}
          </div>

          <!-- <Transition>
            <div class="desc red" v-if="error">{{ error }}</div>
          </Transition> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderModal',
  props: {
    paymentMethods: {
      type: Array,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    orderData: {
      type: Object,
      required: true
    }

  },
  data: function () {
    return {
      appDomain: ''
    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
    this.appDomain = process.env.VUE_APP_DOMAIN;
  }
}
</script>
<style lang="scss" scoped>
.order-modal .wrapper .container {
  max-height: calc(100vh - 32px);
  display: block;
  overflow-y: auto;
}

.order-modal {
  z-index: 5;
  padding: 16px;
}

.order-modal .wrapper {
  max-width: 1300px;
}

.order-modal .title {
  text-align: center;
}

.button-list-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.button-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  width: 100%;
}

.img-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  height: 125px;
  color: #fff;
}

.img-btn:hover {
  background: #3A3F56;
}

.img-btn img {
  max-width: 100%;
  height: 60px;
  object-fit: contain;
}

.img-btn span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .modal .wrapper .container {
    padding: 10px;
  }
}
</style>