<template>
    <div class="pagination-container">
        <div class="result">Result</div>
        <div class="items-per-page">
            <div class="filter-dropdown" ref="dropdownRef" @click="toggleDropdown">
                <div>
                    <div class="filter-value">{{ perPage }}</div>
                </div>
                <img src="@/assets/img/arrow-down.svg" class="arrow-down" :class="{ 'rotated': dropdownVisible }"
                    alt="arrow" />
                <div v-if="dropdownVisible" class="dropdown-menu" @click.stop>
                    <div v-for="option in itemsPerPageOptions" :key="option" @click="changeItemsPerPage(option)"
                        class="dropdown-item">
                        {{ option }}
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-controls">
            <button class="arrow-button" @click="prevPage" :disabled="isOnFirstPage">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 1L1.70711 6.29289C1.37377 6.62623 1.20711 6.79289 1.20711 7C1.20711 7.20711 1.37377 7.37377 1.70711 7.70711L7 13"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>

            <div class="page-numbers">
                <span @click="goToPage(1)" :class="{ active: currentPage === 1 }" class="page-number">1</span>
                <span v-if="startPage > 2" class="dots">...</span>
                <span v-for="page in displayedPages" :key="page" @click="goToPage(page)"
                    :class="{ active: currentPage === page }" class="page-number">
                    {{ page }}
                </span>
                <span v-if="endPage < totalPages - 1" class="dots">...</span>
                <span v-if="totalPages > 1" @click="goToPage(totalPages)"
                    :class="{ active: currentPage === totalPages }" class="page-number">
                    {{ totalPages }}
                </span>
            </div>

            <button class="arrow-button" @click="nextPage" :disabled="isOnLastPage">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 13L6.29289 7.70711C6.62623 7.37377 6.79289 7.20711 6.79289 7C6.79289 6.79289 6.62623 6.62623 6.29289 6.29289L1 1"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'

export default {
    props: {
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 20
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },
    emits: ['update:perPage', 'update:currentPage'],
    data() {
        return {
            dropdownVisible: false,
            itemsPerPageOptions: [16, 32, 48],
        };
    },
    computed: {
        startPage() {
            if (this.currentPage <= 3) return 2;
            if (this.currentPage >= this.totalPages - 2) return this.totalPages - 3;
            return this.currentPage - 1;
        },
        endPage() {
            if (this.currentPage <= 3) return Math.min(5, this.totalPages - 1);
            if (this.currentPage >= this.totalPages - 2) return this.totalPages - 1;
            return this.currentPage + 1;
        },
        displayedPages() {
            const pages = [];
            for (let i = this.startPage; i <= this.endPage; i++) {
                if (i !== 1 && i !== this.totalPages) {
                    pages.push(i);
                }
            }
            return pages;
        },
        isOnFirstPage() {
            return this.currentPage === 1;
        },
        isOnLastPage() {
            return this.currentPage === this.totalPages;
        }
    },
    mounted() {
        onClickOutside(this.$refs.dropdownRef, () => {
            this.dropdownVisible = false;
        });
    },
    methods: {
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        changeItemsPerPage(newPerPage) {
            this.$emit('update:perPage', newPerPage);
            this.dropdownVisible = false;
        },
        goToPage(page) {
            if (page !== this.currentPage) {
                this.$emit('update:currentPage', page);
            }
        },
        prevPage() {
            if (!this.isOnFirstPage) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (!this.isOnLastPage) {
                this.goToPage(this.currentPage + 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    margin-left: auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.result {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: white;
    margin-right: 7px;
}

.items-per-page {
    margin-right: 16px;
}

.filter-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 6px 6px 6px 12px;
    border-radius: 6px;
    border: 1px solid #46566A;
    background: #29374A;
    cursor: pointer;
    position: relative;
    max-width: 72px;
    width: 100%;
    /* height: 32px; */
}

// .filter-label {
//     font-size: 12px;
//     font-weight: 400;
//     line-height: 16px;
//     text-align: left;
//     color: #a8b2bd;
// }

.filter-value {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: white;
}

.arrow-down {
    transition: all 0.3s ease;
}

.arrow-down.rotated {
    transform: rotate(180deg);
}

.dropdown-menu {
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #29374A;
    color: white;
    padding: 5px;
    border: 1px solid #46566A;
    border-radius: 6px;
    width: 100%;
    z-index: 10;
}

.dropdown-item {
    padding: 6px 6px 6px 12px;
    border-radius: 8px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #11131C;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.arrow-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #29374A;
    border: 1px solid #46566A;
    border-radius: 6px;
}

.arrow-button:disabled {
    cursor: not-allowed;

    svg path {
        stroke-opacity: 0.2;
    }
}

.page-numbers {
    display: flex;
    align-items: center;
    margin: 0 8px;
    padding: 6px 8px;
    background: #29374A;
    border: 1px solid #46566A;
    border-radius: 6px;
}

.page-number {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: white;

    &:not(:first-child, :last-child) {
        margin: 0 2px;
    }
}

.page-number.active {
    color: #142D3F;
    background: #3B5FFF;
    border-radius: 4px;
}

.dots {
    color: white;
    cursor: default;
    margin: 0 2px;
}
</style>