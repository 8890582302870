<template>
  <div :class="['app ' + $route.name]">
    <Transition>
      <div v-if="pageLoaded && currentLanguage" id="page" class="page">
        <Transition>
          <MobMenu
            v-if="menuVisible"
            :currency-code="currencyCode"
            :currency-symbol="currencySymbol"
            :currency-options="currencyOptions"
            :currency-code-id="currencyCodeId"
            :cart-contents="cartContents"
            :is-auth="isAuth"
            @changeCurrency="changeCurrency"
            @openSignIn="openSignIn"
            @openSignUp="openSignUp"
            @closeMenu="closeMenu"
          />
        </Transition>
        <div
          :class="['mob menu-icon', { active: menuVisible }]"
          @click="toggleMenu"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Header
          :currency-code="currencyCode"
          :currency-options="currencyOptions"
          :currency-code-id="currencyCodeId"
          :cart-contents="cartContents"
          :is-auth="isAuth"
          @changeCurrency="changeCurrency"
          @openSignIn="openSignIn"
          @openSignUp="openSignUp"
        />
        <router-view
          :currency-code="currencyCode"
          :currency-symbol="currencySymbol"
          :text-page-title="textPageTitle"
          :text-page-header="textPageHeader"
          :text-page-content="textPageContent"
          :is-auth="isAuth"
          :order-history="orderHistory"
          :total-sum="totalSum"
          :error="error"
          :cart-contents="cartContents"
          :hostname="hostname"
          :footer-support-email="footerSupportEmail"
          :chosen-item="chosenItem"
          @formatDate="formatDate"
          @getTextPageContent="getTextPageContent"
          @goToProduct="goToProduct"
          @openSignUp="openSignUp"
          @openSignIn="openSignIn"
          @logout="logout"
          @addToCart="addToCart"
          @removeOneFromCart="removeOneFromCart"
          @removeFromCart="removeFromCart"
          @openOrderModal="openOrderModal"
          @openDiscountModal="openDiscountModal"
          @goToPage="goToPage"
        />
        <Footer
          :text-page-list="textPageList"
          :footer-requisites="footerRequisites"
          :footer-support-email="footerSupportEmail"
          :paymentMethods="allPaymentMethods"
          @goToPage="goToPage"
        />
        <Transition>
          <ProductModal
            v-if="productModalIsVisible"
            :product-page-item="productPageItem"
            :currency-code="currencyCode"
            :currency-symbol="currencySymbol"
            :chosen-item="chosenItem"
            :error="error"
            :loader-is-visible="loaderIsVisible"
            @closeProductModal="closeProductModal"
            @addToCart="addToCart"
          />
        </Transition>
        <Transition>
          <LoginModal
            v-if="signInModalIsVisible"
            :error="error"
            :successMessage="successMessage"
            :loader-is-visible="loaderIsVisible"
            :showResendOption="showResendOption"
            @closeSignIn="closeSignIn"
            @openSignUp="openSignUp"
            @login="login"
            @forgotPass="forgotPass"
            @clearError="clearError"
            @hideResendOption="hideResendOption"
            @resendVerificationEmail="resendVerificationEmail"
          />
        </Transition>
        <Transition>
          <RegistrationModal
            v-if="signUpModalIsVisible"
            :error="error"
            :loader-is-visible="loaderIsVisible"
            @closeSignUp="closeSignUp"
            @openSignIn="openSignIn"
            @registration="registration"
            @goToPage="goToPage"
          />
        </Transition>
        <Transition>
          <OrderModal
            v-show="orderModalIsVisible"
            :orderData="orderData"
            :payment-methods="availablePaymentMethods"
            :error="error"
            @orderSubmit="orderSubmit"
            @closeOrderModal="closeOrderModal"
          />
        </Transition>

        <Transition>
          <DiscountModal
            v-show="discountModalVisible && isAuth"
            ref="discountModal"
            :is-auth="isAuth"
            :currency-code="currency"
            :currency-symbol="currencySymbol"
            :add-to-cart-chosen-item="addToCartChosenItem"
            @addToCart="addToCart"
            @goToProduct="goToProduct"
            @closeDiscountModal="closeDiscountModal"
          />
        </Transition>

        <CookiesModal @navigate="goToPage" />
      </div>
    </Transition>

    <ChatBot />
  </div>
</template>

<script>
import Header from "./components/HeaderComponent.vue";
import Footer from "./components/FooterComponent.vue";
import ProductModal from "./components/ProductModal.vue";
import MobMenu from "./components/MobMenu.vue";
import LoginModal from "./components/LoginModal.vue";
import RegistrationModal from "./components/RegistrationModal.vue";
import OrderModal from "./components/OrderModal.vue";
import CookiesModal from "./components/CookiesModal.vue";
import DiscountModal from "./components/DiscountModal.vue";
import { loadScriptsFromString } from "@/utils/load_scripts";
import { mapActions, mapGetters, mapState } from "vuex";
import ChatBot from "./components/ChatBot.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    ProductModal,
    MobMenu,
    LoginModal,
    RegistrationModal,
    OrderModal,
    CookiesModal,
    DiscountModal,
    ChatBot,
  },
  data: function () {
    return {
      isAuth: false,
      showResendOption: false,
      currencyCodeId: "",
      currencyOptions: [],
      pageLoaded: false,
      error: "",
      productModalIsVisible: false,
      productPageItem: {},
      textPageList: [],
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      menuVisible: false,
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      successMessage: "",
      cartContents: [],
      totalSum: "",
      chosenItem: "",
      loaderIsVisible: false,
      orderHistory: [],
      orderModalIsVisible: false,
      orderData: {},
      allPaymentMethods: [],
      availablePaymentMethods: [], // Store available payment methods for the current order
      hostname: "",
      cookesModalIsVisible: false,
    };
  },
  computed: {
    ...mapState("app", ["currentLanguage"]),
    ...mapGetters("app", ["currencyCode", "currencySymbol"]),
    ...mapGetters("discount", ["hasActiveDiscount", "discountModalVisible"]),
  },
  watch: {
    async isAuth() {
      if (this.isAuth) {
        await this.fetchActiveDiscount(this.currencyCode);
        if (this.hasActiveDiscount) {
          this.openDiscountModal();
        }
      }
    },
    currencyCode() {
      if (this.productPageItem && this.productPageItem.length) {
        this.getProductPageContent(this.productPageItem);
      }
      if (this.isAuth) {
        this.orderHistory = "";
        this.cartContents = "";
        this.totalSum = "";
        this.getOrderHistory();
        this.getCartContents();
      }
    },
    $route() {
      this.error = "";
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
    },
  },
  methods: {
    ...mapActions("app", ["fetchCurrencies", "changeCurrency"]),
    ...mapActions("discount", [
      "fetchActiveDiscount",
      "openDiscountModal",
      "closeDiscountModal",
    ]),
    async fetchPaymentMethods(countryId, currencyCode) {
      this.$http
        .post(process.env.VUE_APP_API + "orders/payment-methods", {
          country: countryId,
          currency: currencyCode,
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.availablePaymentMethods = response.data.payload;
          } else {
            this.error = response.data.message;
          }
        })
        .catch((error) => {
          console.error("Error fetching payment methods:", error);
          this.error = "Error fetching payment methods";
        });
    },
    resendVerificationEmail(data) {
      this.error = "";
      this.$http
        .post(process.env.VUE_APP_API + "resendConfirmationLink", {
          email: data,
        })
        .then((res) => {
          if (res.data.status == "OK") {
            this.error = "";
            this.successMessage = this.$t("Confirmation link has been resent.");
          } else {
            this.error = res.data.message;
            this.successMessage = "";
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
        });
    },
    getDomainName(hostName) {
      return hostName.substring(
        hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
      );
    },
    orderSubmit(data, type) {
      data.paymentType = type;
      data.currency = this.currencyCode;
      this.$http
        .post(process.env.VUE_APP_API + "orders/checkout", data)
        .then((res) => {
          if (res.data.status == "ERROR") {
            this.$router.push({ name: "PaymentFaliPage" });
            this.closeOrderModal();
          } else {
            window.location = res.data.redirect_url;
          }
        })
        .catch((res) => {
          this.$toast.error(res.response.data.message, {
            multiple: false,
          });
          this.error = res.response.data.message;
        });
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = "";
    },
    openOrderModal(data) {
      this.orderModalIsVisible = true;
      document.body.style.overflow = "hidden";
      this.orderData = data;
      this.fetchPaymentMethods(this.orderData.country, this.currencyCode);
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "orders/history")
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    addToCart(id, item_type) {
      if (!this.isAuth) {
        this.openSignIn();
      } else {
        let self = this;

        let cartItem = {
          id: id,
          count: 1,
          item_type: item_type,
        };

        for (var i = 0; i < this.cartContents.length; i++) {
          if (
            this.cartContents[i].item_id == id &&
            this.cartContents[i].item.item_type === cartItem.item_type
          ) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              ...cartItem,
              count: cartItemCount + 1,
            };
            break;
          }
        }
        this.loaderIsVisible = true;
        this.$http
          .post(process.env.VUE_APP_API + "cart/add", cartItem)
          .then((res) => {
            self.loaderIsVisible = false;
            this.getCartContents();
            self.chosenItem = cartItem.id;
            self.cartContents = res.data.cart;
            setTimeout(() => {
              self.chosenItem = "";
            }, 2000);
          })
          .catch((res) => {
            self.loaderIsVisible = false;
            if (res.response.status === 401) {
              this.openSignIn();
            } else {
              self.error = res.response.data.message;
              setTimeout(() => {
                self.error = "";
              }, 2000);
            }
          });
      }
    },
    removeOneFromCart(item, id, item_type) {
      let self = this;
      let cartItem = {
        id: id,
        count: "1",
        item_type: item_type,
      };
      if (item.count > 1) {
        cartItem = {
          ...cartItem,
          count: item.count - 1,
        };
        this.$http
          .post(process.env.VUE_APP_API + "cart/add", cartItem)
          .then(() => {
            self.getCartContents();
          })
          .catch(() => {});
      } else {
        this.removeFromCart(item.item, id, item_type);
      }
    },
    removeFromCart(item, id, item_type) {
      let self = this;
      let cartItem = {
        id: id,
        item_type: item_type,
      };
      this.$http
        .post(process.env.VUE_APP_API + "cart/remove", cartItem)
        .then(() => {
          self.getCartContents();
        })
        .catch(() => {});
    },
    checkIsAuth() {
      this.$http
        .get(process.env.VUE_APP_API + "is-auth")
        .then((res) => {
          this.isAuth = res.data.authenticated;
          if (res.data.authenticated) {
            this.getCartContents();
            this.getOrderHistory();
          }
        })
        .catch(() => {});
    },
    login(data) {
      this.loaderIsVisible = true;
      this.showResendOption = false;

      this.$http
        .post(process.env.VUE_APP_API + "login", data)
        .then((res) => {
          this.loaderIsVisible = false;
          if (res.data.status == "OK") {
            this.isAuth = true;
            this.closeSignIn();
            this.getCartContents();
            this.getOrderHistory();
          } else {
            this.error = res.data.message;
          }
        })
        .catch((res) => {
          this.loaderIsVisible = false;
          this.error = res.response.data.message;
          if (res.response.data.status === "VERIFY") {
            this.showResendOption = true;
          }
        });
    },
    forgotPass(data) {
      let self = this;
      self.loaderIsVisible = true;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          self.loaderIsVisible = false;
          if (res.data.status == "OK") {
            this.successMessage = res?.data?.message || "Success";
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2500);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          self.loaderIsVisible = false;
          this.error = error.response.data.message;
        });
    },
    registration(data) {
      if (
        !/^[a-zA-Z\s-]+$/.test(data.name) ||
        !/^[a-zA-Z\s-]+$/.test(data.surname)
      ) {
        this.error = "First name and Last name must only contain letters";
        return;
      }
      let self = this;
      self.loaderIsVisible = true;
      this.$http
        .post(process.env.VUE_APP_API + "register", data)
        .then((res) => {
          self.loaderIsVisible = false;
          if (res.data.status == "OK") {
            self.clearError();
            self.isAuth = true;
            self.closeSignUp();
            self.getCartContents();
            self.getOrderHistory();
          } else {
            self.error = res.data.message;
          }
        })
        .catch((res) => {
          self.loaderIsVisible = false;
          this.error = res.response.data.message;
        });
    },
    logout() {
      this.$http
        .post(process.env.VUE_APP_API + "logout")
        .then(() => {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }
          this.isAuth = false;
          this.$router.push({ path: "/" });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            console.log(res);
          }
        });
    },
    openSignIn() {
      this.error = "";
      this.showResendOption = false;
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignIn() {
      this.error = "";
      this.showResendOption = false;
      this.signInModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.clearError();
    },
    openSignUp() {
      this.error = "";
      this.showResendOption = false;
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignUp() {
      this.error = "";
      this.showResendOption = false;
      this.signUpModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.clearError();
    },
    getProductPageContent(item) {
      this.productPageItem = "";
      const apiEndpoint =
        item.item_type === "pack" ? `packs/${item.slug}` : `items/${item.id}`;
      this.$http
        .get(`${process.env.VUE_APP_API}${apiEndpoint}`, {
          params: { currency: this.currencyCode },
        })
        .then((res) => {
          this.productPageItem = res.data.payload;
        })
        .catch((error) => {
          this.error = "Failed to load product data.";
          console.error(error);
        });
    },
    goToProduct(item) {
      const type = item.item_type === "pack" ? "pack" : "item";
      const id = item.item_type === "pack" ? item.slug : item.id;
      this.$router.push({ name: "ProductPage", params: { type, id } });
    },
    openProductModal() {
      this.productModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeProductModal() {
      this.productModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.clearError();
    },
    clearError() {
      this.error = "";
    },
    hideResendOption() {
      this.showResendOption = false;
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("privacy")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("terms")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "cookie") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("cookie")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
      this.closeSignUp();
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id, {
          params: {
            lang_id: this.currentLanguage,
          },
        })
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch(() => {});
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages", {
          params: {
            lang_id: this.currentLanguage,
          },
        })
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch(() => {});
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    getCartContents() {
      this.$http
        .get(
          process.env.VUE_APP_API +
            "cart/contents" +
            "?currency=" +
            this.currencyCode
        )
        .then((res) => {
          this.cartContents = res.data.cart;
          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice =
                this.cartContents[i].count *
                parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignIn();
          }
        });
    },
    getSettings() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          this.allPaymentMethods = res.data.payload.payment_methods;
          this.footerRequisites = res.data.payload.requisites;
          this.footerSupportEmail = res.data.payload.support_email;
          const snippet = res.data.payload.google_tag;
          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch(() => {});
    },
    ...mapActions("app", ["fetchLanguages"]),
  },
  mounted: async function () {
    let self = this;
    await self.fetchLanguages();
    await this.fetchCurrencies();
    if (this.isAuth) {
      await this.fetchActiveDiscount(this.currencyCode);
      if (this.hasActiveDiscount) {
        this.openDiscountModal();
      }
    }
    self.getTextPages();
    self.menuHeight();
    self.getSettings();
    self.checkIsAuth();
    self.hostname = self.getDomainName(window.location.hostname);
    window.addEventListener("resize", self.menuHeight);
    setTimeout(function () {
      self.pageLoaded = true;
    }, 300);
  },
};
</script>
