<template>
  <div class="modal sign-modal reg-modal">
    <div class="overlay" @click="$emit('closeSignUp')" />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              class="close"
              src="./../assets/img/close.svg"
              @click="$emit('closeSignUp')"
            />
            <div class="modal-main">
              <div class="form-fields register-content">
                <div class="form-fields-wrapper">
                  <div class="title small">
                    {{ $t("Welcome") }}
                  </div>
                  <label>
                    <input
                      v-model="name"
                      type="text"
                      placeholder="First name*"
                    />
                  </label>
                  <label>
                    <input
                      v-model="surname"
                      type="text"
                      placeholder="Last Name*"
                    />
                  </label>
                  <label>
                    <input ref="dob" @focus="showDatePicker" @blur="hideDatePicker" type="text" :placeholder="$t('Date of Birth')" v-model="dob" required />
                  </label>
                  <label>
                    <input v-model="email" type="email" placeholder="Email*" />
                  </label>
                  <label>
                    <MazPhoneNumberInput
                      v-model="phone"
                      v-model:country-code="countryCode"
                      placeholder="Phone number*"
                      show-code-on-list
                      :only-countries="countryOptionsIso"
                      country-locale="en-EN"
                      @update="phoneDataUpdate($event)"
                    />
                  </label>
                  <label>
                    <input
                      v-model="pass"
                      type="password"
                      placeholder="Password*"
                    />
                  </label>
                  <label>
                    <input v-model="passConfirm" type="password" placeholder="Confirm Password*" />
                  </label>

                  <label class="country-select-container">
                    <div class="select-wrapper">
                      <select v-model="countryId" :class="{ 'empty': !countryId }">
                        <option value="" selected>{{ $t("Country") }}*</option>
                        <option v-for="option in countryOptions" :key="option.id" :value="option.id">
                          {{ option.title }}
                        </option>
                      </select>
                      <span v-if="countryId" class="clear-icon" @click="clearCountry">&times;</span>
                    </div>
                  </label>

                  <label>
                    <input type="text" :placeholder="$t('City')" v-model="city" />
                  </label>

                  <label>
                    <input type="text" :placeholder="$t('Address')" v-model="address" />
                  </label>

                  <label>
                    <input type="text" :placeholder="$t('Post Code')" v-model="zip" />
                  </label>

                  <label>
                    <input type="text" :placeholder="$t('Referral Code')" v-model="referralCode" />
                  </label>

                  <div class="cta-container">
                    <div class="checkbox-container">
                      <label class="checkbox-label">
                        <div class="checkbox-label-wrapper">
                          <input v-model="terms" type="checkbox" name="terms" />
                          <div class="checkbox" />
                          <span class="desc">{{ $t("I agree with") }} </span>
                          <a class="desc" @click="goToPage('privacy')">
                            {{ $t("Privacy Policy") }}
                          </a>
                          <span class="desc"> {{ $t("and") }} </span>
                          <a class="desc" @click="goToPage('terms')">
                            {{ $t("Terms of use") }}
                          </a>
                        </div>
                      </label>
                    </div>
                    <button
                      :class="[
                        'button blue',
                        { disabled: !requiredRegisterFieldsAreFilled },
                      ]"
                      @click="submitRegister"
                    >
                      <div v-if="loaderIsVisible" class="loader-container">
                        <div class="loader"></div>
                      </div>
                      <span v-if="!loaderIsVisible">{{ $t("Sign up") }}</span>
                    </button>
                  </div>
                  <Transition>
                    <div v-if="error" class="error-desc desc red">
                      {{ error }}
                    </div>
                  </Transition>
                  <div class="modal-bottom">
                    <div class="desc">
                      {{ $t("Already have an account?") }}
                    </div>
                    <a class="desc link" @click="openSignInModal()">
                      {{ $t("Sign in") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationModal",
  props: {
    error: {
      type: String,
      required: true
    },
    loaderIsVisible: {
			type: Boolean,
			required: true
		},
  },
  data: function () {
    return {
      email: "",
      pass: "",
      name: "",
      surname: "",
      phone: "",
      passConfirm: "",
      terms: false,
      countryCode: '',
      phoneFull: "",
      countryOptions: [],
      countryOptionsIso: [],
      dob: null,
      address: "",
      city: "",
      zip: "",
      countryId: '',
      referralCode: '',
    };
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.phone &&
        this.email &&
        this.dob &&
        this.pass &&
        this.passConfirm &&
        this.terms &&
        this.countryId
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    clearCountry() {
      this.countryId = '';
    },
    goToPage(id) {
      this.$emit('goToPage', id);
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational || e.phoneNumber;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
          this.countryCode = this.countryOptionsIso[0];
        })
        .catch(() => {
        })
    },
    closeSignUpModal() {
      this.$emit("closeSignIn");
    },
    openSignInModal() {
      this.$emit("openSignIn");
    },
    submitRegister() {
      let regData = {
        dob: this.dob,
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phoneFull,
        password: this.pass,
        passConfirm: this.passConfirm,
        address: this.address,
        city: this.city,
        zip: this.zip,
        country_id: this.countryId,
        referral_code: this.referralCode
      };
      this.$emit("registration", regData);
    },
    showDatePicker() {
      this.$refs.dob.type = 'date';
      this.$refs.dob.focus();
    },
    hideDatePicker() {
      if (!this.dob) {
        this.$refs.dob.type = 'text';
        this.dob = '';
      }
    }
  },
};
</script>
