<template>
    <div v-if="product">

        <div class="product-details-container">
            <div class="product-image-container">
                <img :src="product.image_url || (imgDomain + product.img_url)" :alt="product.name || product.title"
                    class="product-image">
            </div>
            <div class="product-info-container">
                <h1 class="product-title">{{ product.name || product.title }}</h1>
                <p v-if="product.type && type == 'item'" class="product-type">Type: {{ product.type }}</p>
                <p v-if="product.type && type == 'pack'" class="product-type">Type: Pack</p>
                <p v-if="product.quality" class="product-quality">Quality: {{ product.quality }}</p>
                <div class="price-container"
                    :class="{ 'has-discount': product.old_price && product.old_price != '0.00' }">
                    <template v-if="product.old_price && product.old_price != '0.00'">
                        <div class="old-price">{{ product.old_price }} {{ currencySymbol }}</div>
                        <div class="price desc discounted">
                            <span>
                                <b>{{ product.price }} {{ currencySymbol }}</b>
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="price desc">
                            <span>
                                <b>{{ product.price }} {{ currencySymbol }}</b>
                            </span>
                        </div>
                    </template>
                </div>

                <div v-if="type == 'item' && chartData.length > 0" class="chart-section">
                    <h3 class="chart-title">Sale price</h3>
                    <div class="chart-container">
                        <canvas ref="chartCanvas"></canvas>
                    </div>
                </div>

                <div class="content-desc" v-if="product.type && type == 'pack'" v-html="product.content_desc">
                </div>

                <button class="add-to-cart-button button" @click="addToCart(product)">Add to cart</button>
                <transition>
                    <div v-if="chosenItem" class="desc green">
                        {{ $t('Added') }}
                    </div>
                </transition>

                <div class="disclaimer" v-if="type == 'pack'">
                    <img src="@/assets/img/tooltip.svg" alt="">
                    <p>
                        Please be advised that items obtained from these cases are distributed on a purely random basis.
                        The value of the items you receive can vary significantly, generally ranging from equal to the
                        cost of the case itself to potentially slightly exceeding the case's value. It's important to
                        understand that due to the random nature of item distribution, we cannot guarantee the exact
                        value or specific items you will receive from any given case. By purchasing a case, you
                        acknowledge and accept these terms and conditions of the platform. If you have any questions or
                        concerns, please contact our customer support before making a purchase.
                    </p>
                </div>

            </div>
        </div>
    </div>
    <div v-else>
        Loading...
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
export default {
    name: 'ProductPage',
    props: {
        type: {
            type: String
        },
        id: {
            type: String,
        },
        chosenItem: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            product: null,
            chart: null,
            chartData: [],
            imgDomain: '',
        };
    },
    computed: {
        ...mapGetters('app', ['currencyCode', 'currencySymbol']),
    },
    created() {
        this.fetchProductData();
    },
    mounted() {
        this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    },
    watch: {
        currencyCode() {
            this.fetchProductData();
        },
        chartData(newData) {
            if (newData.length > 0) {
                this.createChart();
            }
        }
    },
    methods: {
        addToCart(item) {
            this.$emit('addToCart', item.id, item.item_type);
        },
        fetchProductData() {
            const apiEndpoint = this.type === 'pack' ? `packs/${this.id}` : `items/${this.id}`;
            this.$http.get(`${process.env.VUE_APP_API}${apiEndpoint}`, { params: { currency: this.currencyCode, include_price_history: true } })
                .then((res) => {
                    this.product = res.data.payload;
                    // this.chartData = this.product?.price_history || [];
                    // this.chartData = [
                    // { date: 'Dec 10 2024', price: 250, count: 1 },
                    // { date: 'Dec 21 2024', price: 280, count: 1 },
                    // { date: 'Jan 1 2024', price: 300, count: 1 },
                    // { date: 'Jan 12 2024', price: 320, count: 2 },
                    // { date: 'Jan 24 2024', price: 310, count: 1 },
                    // { date: 'Feb 4 2024', price: 330, count: 1 },
                    // { date: 'Feb 16 2024', price: 350, count: 1 },
                    // { date: 'Feb 27 2024', price: 340, count: 3 },
                    // ]
                })
                .catch((error) => {
                    console.error('Failed to load product data:', error);
                });
        },
        createChart() {
            this.$nextTick(() => {
                const canvas = this.$refs.chartCanvas;
                if (canvas && this.chartData.length > 0) {
                    this.destroyChart();
                    const ctx = canvas.getContext('2d');

                    this.chart = new Chart(ctx, {
                        type: 'line',
                        data: {
                            labels: this.chartData.map(data => data.date),
                            datasets: [{
                                label: 'Price',
                                data: this.chartData.map(data => data.price),
                                borderColor: '#3B5FFF',
                                borderWidth: 2,
                                pointBackgroundColor: '#3B5FFF',
                                pointBorderColor: '#3B5FFF',
                                pointRadius: 0,
                                pointHoverRadius: 5,
                                tension: 0
                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: {
                                padding: {
                                    top: 16,
                                    right: 42,
                                    bottom: 16,
                                    left: 16
                                }
                            },
                            scales: {
                                x: {
                                    // type: 'time',
                                    // time: {
                                    //     unit: 'day',
                                    //     displayFormats: {
                                    //         day: 'MMM D'
                                    //     },
                                    //     tooltipFormat: 'MMM D YYYY'
                                    // },
                                    grid: {
                                        color: (context) => {
                                            if (context.index === 0 || context.index == this.chartData.length - 1) {
                                                return 'transparent';
                                            }
                                            return '#3B4858';
                                        },
                                        lineWidth: 1,
                                        drawBorder: false
                                    },
                                    border: { display: false },
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 8,
                                        font: {
                                            family: 'Oxanium',
                                            size: 14,
                                            weight: 500
                                        },
                                        color: 'white',
                                        callback: (index) => {
                                            return this.chartData[index].date.slice(0, -4);
                                        }
                                    },
                                },
                                y: {
                                    grid: {
                                        color: '#3B4858',
                                        lineWidth: 1,
                                        drawBorder: false,
                                    },
                                    border: { display: false },
                                    ticks: {
                                        font: {
                                            family: 'Oxanium',
                                            size: 14,
                                            weight: 500
                                        },
                                        autoSkip: false,
                                        count: 7,
                                        color: 'white',
                                        callback: (value, index, values) => (index == (values.length - 1) || index == 0) ? undefined : ` ${value.toFixed(2)} €`,
                                    },
                                    afterDataLimits: (axis) => {
                                        const range = axis.max - axis.min;
                                        const pixelValue = 31;
                                        const chartHeight = axis.chart.height;
                                        const valuePerPixel = range / chartHeight;
                                        const valueIncrease = pixelValue * valuePerPixel;

                                        axis.max += valueIncrease;
                                        axis.min -= valueIncrease;
                                    }
                                }
                            },
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    backgroundColor: '#16171C',
                                    titleFont: {
                                        family: 'Oxanium',
                                        size: 14,
                                        weight: 500
                                    },
                                    bodyFont: {
                                        family: 'Oxanium',
                                        size: 14,
                                        weight: 500
                                    },
                                    padding: 10,
                                    cornerRadius: 8,
                                    displayColors: false,
                                    boxPadding: 0,
                                    callbacks: {
                                        title: (tooltipItems) => {
                                            const item = tooltipItems[0];
                                            return `${item.label}`;
                                        },
                                        label: (context) => {
                                            return `CDN ${context.parsed.y.toFixed(2)} €`;
                                        },
                                        afterLabel: (context) => {
                                            const dataPoint = this.chartData[context.dataIndex];
                                            return `${dataPoint.count} sold`;
                                        }
                                    }
                                },
                            },
                            elements: {
                                point: {
                                    radius: 1,
                                    hoverRadius: 8,
                                    hitRadius: 20
                                }
                            }
                        }
                    });
                }
            });
        },
        destroyChart() {
            if (this.chart) {
                this.chart.destroy();
                this.chart = null;
            }
        }
    }
}
</script>



<style scoped lang="scss">
.product-details-container {
    display: flex;
    max-width: 1240px;
    min-height: 618px;
    margin: 0 auto;
    padding: 24px 10px 95px 10px;
}

.product-image-container {
    padding-top: 16px;
    width: 572px;
    height: 426px;
    // background: #20222F;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.product-image {
    display: inline-block;
    max-width: 442px;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
    max-height: 332px;
}

.product-info-container {
    flex: 1;
    padding-left: 47px;
}

.product-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 68.64px;
    text-align: left;
    color: white;
    margin-bottom: 12px;
}

.product-type,
.product-quality {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.88px;
    text-align: left;
    color: white;
    margin-bottom: 8px;
}

.price-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    height: 26px;
}

.price-container.has-discount {
    gap: 8px;
    align-items: center;
}

.old-price {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: white;
    text-decoration: line-through;
}

.price {
    font-size: 24px;
    font-weight: 700;
    color: #3B5FFF;
    text-align: left;
}

.price.discounted {
    text-align: right;
    color: #E63333;
}

.chart-section {
    margin-bottom: 26px;
}

.chart-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.88px;
    text-align: left;
    color: white;
    margin-bottom: 8px;
}

.chart-container {
    background: #20222F;
    height: 300px;
    width: 100%;
    border-radius: 16px;
    position: relative;
}

.content-desc {
    margin-top: 24px;
    color: white;
}

.add-to-cart-button {
    margin-top: 24px;
    max-width: 273px;
    border-radius: 40px;
}

.disclaimer {
    color: white;
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    text-align: left;
}

.desc.green {
    text-align: center;
    max-width: 273px;
    width: 100%;
}

/* Add responsive styles */
@media (max-width: 1220px) {
    .product-details-container {
        flex-direction: column;
        align-items: center;
    }

    .product-image-container {
        width: 100%;
        max-width: 572px;
        height: auto;
        aspect-ratio: 572 / 426;
    }

    .product-info-container {
        padding-left: 0;
        padding-top: 20px;
        width: 100%;
        max-width: 572px;
    }
}
</style>